
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { ref } from "vue";
import store from "@/state/store";
import useVuelidate from "@vuelidate/core";
// import bannerModel from './IncomingChickens/index.vue'
var state = store._modules.root._children.auth.state;
import DropZone from "@/components/widgets/dropZone";
import Switches from "vue-switches";
import { Modal } from "bootstrap";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    let dropzoneFile = ref("");
    let url = ref(null);
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      url = URL.createObjectURL(e.dataTransfer.files[0]);
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      document.querySelector(".img-prev").appendChild(img);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      url = URL.createObjectURL(
        document.querySelector(".dropzoneFile").files[0]
      );
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      img.style.width = "150px";
      img.style.height = "150px";
      document.querySelector(".img-prev").appendChild(img);
      console.log(dropzoneFile.value);
      console.log(url);
    };
    // this.archive.file=url
    return { url, dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },

  components: {
    Layout,
    PageHeader,
    Switches,
    DropZone,
    // bannerModel
  },
  data() {
    return {
      detailModal: "",
      Banners: [],
      services: [],
      vendors: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchBanners: null,
      BannersSearchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    showModel() {
      this.detailModal.show();
      // this.popup.modal(data);
    },
    switchStatus(app) {
      // this.status = !this.status
      app.status = !app.status;
      this.http.put("banners", app?.id, { status: app.status }).then((res) => {
        if (res.status) {
          this.get(this.page);
        }
      });
    },

    addBanners() {
      this.Banners.image = this.dropzoneFile;
      this.http.do("banners", this.Banners).then(() => {
        this.get(this.page);
      });
    },
    editBanners(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "image",
            type: "file",
            label: "image",
            value: app.image,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("banners", app.id, obj).then(() => {
                this.detailModal.hide();
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateBanners(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "image",
            type: "text",
            label: "image",
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("banners", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.BannersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("banners/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.Banners = res.data;
          console.log("######## files", this.Banners);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.BannersSearchMode = false;
      this.get(this.page);
    },

    getBanners() {
      this.http.get("banners").then((res) => {
        this.Banners = res.data;
      });
    },
    deleteBanners(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("banners", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },
    getServices() {
      this.http.get("vendor-catogries").then((res) => {
        if (res.status) {
          this.services = res.data;
          console.log(this.services);
        }
      });
    },
    getVendors() {
      this.http.get("vendors").then((res) => {
        if (res.status) {
          this.vendors = res.data;
          console.log(this.vendors);
        }
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("banners/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Banners = res.data;
        });
    },
  },
  created() {
    this.get(1);
    this.getServices();
    this.getVendors();
    // this.getBanners();
  },
  mounted() {
    this.detailModal = new Modal(`#banner`);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.banners.text')"
    />

    <div class="container">
      <div class="row">
        <div
          class="modal fade"
          id="banner"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <div class="modal-dialog" style="max-width: 1024px">
            <div class="modal-content">
              <form @submit.prevent="addBanners()">
                <div class="modal-header pt-4 px-4">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ $t("popups.add") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close-add"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style="margin: 0"
                  >
                    X
                  </button>
                </div>

                <div class="modal-body px-0">
                  <div class="container">
                    <div class="row">
                      <div class="col">
                        <label for="">{{ $t("banners.type") }}</label>
                        <select class="form-control" v-model="Banners.type">
                          <option :value="1">
                            {{ $t("banners.general") }}
                          </option>
                          <option :value="2">
                            {{ $t("banners.services") }}
                          </option>
                          <option :value="3">{{ $t("banners.vendor") }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" v-if="Banners.type == 1">
                        <label>{{ $t("products.new.img") }}</label>
                        <DropZone
                          @drop.prevent="drop"
                          @change="selectedFile()"
                        />
                        <div class="my-2 img-prev"></div>
                        <div class="mb-3" style="display: none">
                          <label for="editorInpt">
                            {{ $t("products.new.descr") }}
                          </label>
                          <textarea
                            id="editorInpt"
                            class="form-control"
                            v-model="Banners.image"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col" v-if="Banners.type == 2">
                        <div class="row mt-2">
                          <label for="">{{ $t("banners.services") }}</label>

                          <div class="col">
                            <select
                              class="form-control"
                              v-model="Banners.service_id"
                              id=""
                            >
                              <option
                                v-for="service in services"
                                :value="service.id"
                                :key="service"
                              >
                                {{ service?.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <label>{{ $t("banners.image") }}</label>
                        <DropZone
                          @drop.prevent="drop"
                          @change="selectedFile()"
                        />
                        <div class="my-2 img-prev"></div>
                        <div class="mb-3" style="display: none">
                          <label for="editorInpt">
                            {{ $t("products.new.descr") }}
                          </label>
                          <textarea
                            id="editorInpt"
                            class="form-control"
                            v-model="Banners.image"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col" v-if="Banners.type == 3">
                        <div class="row mt-2">
                          <label for="">{{ $("banners.vendors") }}</label>
                          <div class="col">
                            <select
                              class="form-control"
                              v-model="Banners.vendor_id"
                              id=""
                            >
                              <option
                                v-for="vendor in vendors"
                                :value="vendor.id"
                                :key="vendor"
                              >
                                {{ service?.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <label>{{ $t("products.new.img") }}</label>
                        <DropZone
                          @drop.prevent="drop"
                          @change="selectedFile()"
                        />
                        <div class="my-2 img-prev"></div>
                        <div class="mb-3" style="display: none">
                          <label for="editorInpt">
                            {{ $t("products.new.descr") }}
                          </label>
                          <textarea
                            id="editorInpt"
                            class="form-control"
                            v-model="Banners.image"
                            rows="5"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    class="btn btn-primary mx-1"
                    type="submit"
                    href="javascript: void(0);"
                  >
                    {{ $t("popups.submit") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6"></div>
        <div class="col-6" style="display: flex; justify-content: flex-end">
          <button
            class="btn-close text-light"
            v-if="BannersSearchMode"
            @click="cancelappsearchMode()"
          ></button>
          <button
            type="button"
            @click="showModel()"
            class="btn btn-light float-end mb-4"
          >
            <span
              class="bx bxs-plus-circle float-end fa-2x"
              style="color: #2a3042 !important"
            ></span>
          </button>
        </div>
      </div>

      <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
        <thead>
          <tr
            class="text-light text-center"
            style="background-color: #2a3042 !important"
          >
            <th scope="col">{{ $t("banners.id") }}</th>
            <th scope="col">{{ $t("banners.page") }}</th>
            <th scope="col" colspan="1">{{ $t("banners.status") }}</th>
            <th scope="col">{{ $t("banners.image") }}</th>
            <th scope="col">{{ $t("banners.operations") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(app, index) in Banners" :key="app" class="text-center">
            <td>{{ index + 1 }}</td>
            <td v-if="app?.service_id != null">
              {{
                $i18n.locale == "ar"
                  ? app?.service?.name_ar
                  : app?.service?.name
              }}
            </td>
             <td v-if="app?.service_id == null">
              {{
                $i18n.locale == "ar"
                  ?"الرئيسية"
                  : "main"
              }}
            </td>
            <td colspan="1">
              <switches
                @change="switchStatus(app)"
                :value="app.status"
                color="success"
                class="ms-1"
              ></switches>
            </td>
            <td>
              <img
                :src="
                  $store.state?.auth?.mediaUrl + app?.image?.split('public')[1]
                "
                style="width: 64px; height: 64px"
                alt
              />
            </td>

            <td class="d-flex">
              <a
                class="btn btn-primary mx-1"
                @click="editBanners(app)"
                href="javascript: void(0);"
                role="button"
              >
                {{ $t("popups.edit") }}
              </a>
              <a
                class="btn btn-danger"
                href="javascript: void(0);"
                role="button"
                @click="deleteBanners(app)"
              >
                {{ $t("popups.delete") }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- end  table -->
      <!-- Button trigger modal -->

      <div v-if="tot_pages == 20">
        <!--   Apps  paginate     -->
        <ul class="paginate paginate-rounded justify-content-center mb-2">
          <li class="page-item" :class="{ disabled: page == 1 }">
            <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page - 1)"
              aria-label="Previous"
            >
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: p == page }"
            v-for="p in tot_pages"
            :key="p"
          >
            <a class="page-link" href="javascript: void(0);" @click="get(p)">
              {{ p }}
            </a>
          </li>

          <li class="page-item" :class="{ disabled: page == total_pages }">
            <a
              class="page-link"
              href="javascript: void(0);"
              @click="get(page + 1)"
              aria-label="Next"
            >
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- <bannerModel  :purchase_type="9" :selecting_col="'farm_course_id'" :selector="'banner'"></bannerModel> -->
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




